import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkoutState } from "./actionTypes";
import { axiosContext } from "../context/appContext";
import IWorkoutRequest from "../interfaces/workouts/IWorkoutRequest";
import IWorkoutFull from "../interfaces/workouts/IWorkoutFull";
import { Timestamp } from "firebase/firestore";
import IWeek from "../interfaces/workouts/IWeek";
import { ISimpleWeek } from "../interfaces/workouts/ISimpleWeek";
import IExerciseInfo from "../interfaces/exercises/IExerciseInfo";

const initialState: WorkoutState = {
  workout: {} as IWorkoutFull,
  workouts: [] as Array<IWorkoutFull>,
  subcriptionWorkouts: [] as Array<IWorkoutFull>,
  clientWorkouts: [] as Array<IWorkoutFull>,
  workoutRequest: {} as IWorkoutRequest,
  currentWeeks: [] as Array<IWeek>,
  currentSimpleWeeks: [] as Array<ISimpleWeek>,
  currentWorkout: {} as IWorkoutFull,
  currentExerciseInfo: {} as IExerciseInfo,
  loading: false,
  loadingWorkout: false,
  creating: false,
};

export const fetchSubscriptionWorkouts = createAsyncThunk(
  "client/fetchSubscriptionWorkouts",
  async (payload: { user: string; professor: string }) => {
    try {
      const response = await axiosContext.get(
        `/app/subcription/getSubscriptionWorkouts?client=${payload.user}&professor=${payload.professor}&status=ACTIVE`
      );
      if (response.data?.success) {
        let newWorkouts = response.data?.data || ([] as Array<IWorkoutFull>);

        if (newWorkouts.length) {
          newWorkouts = newWorkouts.map((workout: IWorkoutFull) => {
            const myDate = workout?.date as unknown as {
              _seconds: number;
              _nanoseconds: number;
            };

            const timestamp = new Timestamp(
              myDate._seconds,
              myDate._nanoseconds
            );
            workout.date = timestamp.toDate();
            return workout;
          });
        }

        return newWorkouts;
      }
      return [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
);

export const fetchClientWorkouts = createAsyncThunk(
  "client/fetchClientWorkouts",
  async (client: string) => {
    try {
      const response = await axiosContext.get(
        `/app/clients/getWorkouts?client=${client}`
      );
      if (response.data?.success) {
        let newWorkouts = response.data?.data || ([] as Array<IWorkoutFull>);

        if (newWorkouts.length) {
          newWorkouts = newWorkouts.map((workout: IWorkoutFull) => {
            const myDate = workout?.date as unknown as {
              _seconds: number;
              _nanoseconds: number;
            };

            const timestamp = new Timestamp(
              myDate._seconds,
              myDate._nanoseconds
            );
            workout.date = timestamp.toDate();
            return workout;
          });
        }

        return newWorkouts;
      }
      return [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
);

export const fetchUserWorkouts = createAsyncThunk(
  "user/fetchUserWorkouts",
  async () => {
    try {
      const response = await axiosContext.get(`/app/user/getWorkouts`);
      if (response.data?.success) {
        let newWorkouts = response.data?.data || ([] as Array<IWorkoutFull>);

        if (newWorkouts.length) {
          newWorkouts = newWorkouts.map((workout: IWorkoutFull) => {
            const myDate = workout?.date as unknown as {
              _seconds: number;
              _nanoseconds: number;
            };

            const timestamp = new Timestamp(
              myDate._seconds,
              myDate._nanoseconds
            );
            workout.date = timestamp.toDate();
            return workout;
          });
        }

        return newWorkouts;
      }
      return [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
);

export const editSubscritpionWorkout = createAsyncThunk(
  "workouts/editSubscritpionWorkout",
  async (payload: {
    status?: string;
    workoutId?: string;
    weeks?: Array<IWeek>;
    professor?: string;
    title?: string;
  }) => {
    try {
      const response = await axiosContext.post(
        `/app/subcriptions/editSubscritpionWorkout`,
        {
          id: payload.workoutId,
          status: payload.status,
          weeks: payload.weeks,
          professor: payload.professor,
          title: payload.title,
        }
      );
      if (response.data?.success) {
        let workout = response.data?.data || ({} as IWorkoutFull);

        const myDate = workout?.date as unknown as {
          _seconds: number;
          _nanoseconds: number;
        };

        const timestamp = new Timestamp(myDate._seconds, myDate._nanoseconds);
        workout.date = timestamp.toDate();
        return workout;
      }
      return {} as IWorkoutFull;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IWorkoutFull;
    }
  }
);

export const changeWorkoutExercice = createAsyncThunk(
  "workouts/changeWorkoutExercice",
  async (payload: {
    exercisePosition?: number;
    week?: number;
    day?: number;
    workoutId?: string;
    client: string;
    professor: string;
    reason?: string;
  }) => {
    try {
      const response = await axiosContext.post(`/app/workouts/changeExercise`, {
        id: payload.workoutId,
        week: payload.week,
        day: payload.day,
        exercisePosition: payload.exercisePosition,
        client: payload.client,
        professor: payload.professor,
        reason: payload.reason,
      });
      if (response.data?.success) {
        let workout = response.data?.data || ({} as IWorkoutFull);

        const myDate = workout?.date as unknown as {
          _seconds: number;
          _nanoseconds: number;
        };

        const timestamp = new Timestamp(myDate._seconds, myDate._nanoseconds);
        workout.date = timestamp.toDate();

        return workout;
      }
      return {} as IWorkoutFull;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IWorkoutFull;
    }
  }
);

export const editClientWorkout = createAsyncThunk(
  "workouts/editClientWorkout",
  async (payload: {
    status?: string;
    workoutId?: string;
    weeks?: Array<IWeek>;
    client?: string;
    title?: string;
    done?: boolean;
  }) => {
    try {
      const response = await axiosContext.post(
        `/app/workouts/editClientWorkout`,
        payload
      );
      if (response.data?.success) {
        let workout = response.data?.data || ({} as IWorkoutFull);

        const myDate = workout?.date as unknown as {
          _seconds: number;
          _nanoseconds: number;
        };

        const timestamp = new Timestamp(myDate._seconds, myDate._nanoseconds);
        workout.date = timestamp.toDate();
        return workout;
      }
      return {} as IWorkoutFull;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IWorkoutFull;
    }
  }
);

export const fetchSubscriptionWorkout = createAsyncThunk(
  "client/fetchSubscriptionWorkout",
  async (payload: { workout: string; professor: string }) => {
    try {
      const response = await axiosContext.get(
        `/app/subcription/getSubscriptionWorkout?id=${payload.workout}&professor=${payload.professor}`
      );
      if (response.data?.success) {
        let newWorkout = response.data?.data || ({} as IWorkoutFull);

        const myDate = newWorkout?.date as unknown as {
          _seconds: number;
          _nanoseconds: number;
        };

        const timestamp = new Timestamp(myDate._seconds, myDate._nanoseconds);
        newWorkout.date = timestamp.toDate();

        return newWorkout;
      }
      return {};
    } catch (error) {
      console.error("Error fetching data:", error);
      return {};
    }
  }
);

export const fetchExerciseInfo = createAsyncThunk(
  "exercises/fetchExercise",
  async (payload: { exercise: string; name: string; lang: string }) => {
    try {
      const response = await axiosContext.get(
        `/app/exercises/getExercise?exercise=${payload.exercise}&name=${payload.name}&lang=${payload.lang}`
      );
      if (response.data?.success) {
        const newExercise = response.data?.data as IExerciseInfo;
        console.error(newExercise);
        return newExercise;
      }
      return {} as IExerciseInfo;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IExerciseInfo;
    }
  }
);

export const duplicateClientWorkout = createAsyncThunk(
  "workouts/duplicateClientWorkout",
  async (payload: { workoutId: string; client: string; professor: string }) => {
    try {
      const response = await axiosContext.post(
        `/app/workouts/duplicateWorkout`,
        {
          id: payload.workoutId,
          client: payload.client,
          professor: payload.professor,
        }
      );
      if (response.data?.success) {
        const newWorkout = response.data?.data as IWorkoutFull;
        console.error(newWorkout);
        return newWorkout;
      }
      return {} as IWorkoutFull;
    } catch (error) {
      console.error("Error fetching data:", error);
      return {} as IWorkoutFull;
    }
  }
);

export const workoutSlice = createSlice({
  name: "workout",
  initialState,
  reducers: {
    setStoreWorkout: (state, action: PayloadAction<IWorkoutFull>) => {
      state.workout = action.payload;
    },
    setStoreWorkouts: (state, action: PayloadAction<Array<IWorkoutFull>>) => {
      state.workouts = action.payload;
    },
    setStoreWorkoutRequest: (state, action: PayloadAction<IWorkoutRequest>) => {
      state.workoutRequest = action.payload;
    },
    setStoreCurrentWeeks: (state, action: PayloadAction<Array<IWeek>>) => {
      state.currentWeeks = action.payload;
    },
    setStoreCurrentSimpleWeeks: (
      state,
      action: PayloadAction<Array<ISimpleWeek>>
    ) => {
      state.currentSimpleWeeks = action.payload;
    },
    setStoreCurrentWorkout: (state, action: PayloadAction<IWorkoutFull>) => {
      state.currentWorkout = action.payload;
    },
    setCreating: (state, action: PayloadAction<Boolean>) => {
      state.creating = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientWorkouts.pending, (state) => {
      state.loading = true;
      state.clientWorkouts = [];
    });
    builder.addCase(fetchClientWorkouts.fulfilled, (state, action) => {
      state.clientWorkouts = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchClientWorkouts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchSubscriptionWorkouts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubscriptionWorkouts.fulfilled, (state, action) => {
      state.subcriptionWorkouts = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchSubscriptionWorkouts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchSubscriptionWorkout.pending, (state) => {
      state.loadingWorkout = true;
    });
    builder.addCase(fetchSubscriptionWorkout.fulfilled, (state, action) => {
      state.workout = action.payload;
      state.loadingWorkout = false;
    });
    builder.addCase(fetchSubscriptionWorkout.rejected, (state) => {
      state.loadingWorkout = false;
    });

    builder.addCase(fetchUserWorkouts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserWorkouts.fulfilled, (state, action) => {
      state.workouts = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchUserWorkouts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editClientWorkout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editClientWorkout.fulfilled, (state, action) => {
      state.workout = action.payload;
      state.loading = false;
    });
    builder.addCase(editClientWorkout.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editSubscritpionWorkout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editSubscritpionWorkout.fulfilled, (state, action) => {
      state.workout = action.payload;
      state.loading = false;
    });
    builder.addCase(editSubscritpionWorkout.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(changeWorkoutExercice.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeWorkoutExercice.fulfilled, (state, action) => {
      state.workout = action.payload;
      state.currentWeeks = action.payload?.weeks;
      state.loading = false;
    });
    builder.addCase(changeWorkoutExercice.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(duplicateClientWorkout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(duplicateClientWorkout.fulfilled, (state, action) => {
      state.workout = action.payload;
      state.loading = false;
    });
    builder.addCase(duplicateClientWorkout.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchExerciseInfo.pending, (state) => {
      state.currentExerciseInfo = {} as IExerciseInfo;
    });
    builder.addCase(fetchExerciseInfo.fulfilled, (state, action) => {
      state.currentExerciseInfo = action.payload;
    });
    builder.addCase(fetchExerciseInfo.rejected, (state) => {});
  },
});

export const {
  setStoreWorkout,
  setStoreWorkouts,
  setStoreWorkoutRequest,
  setStoreCurrentWeeks,
  setStoreCurrentWorkout,
  setCreating,
  setStoreCurrentSimpleWeeks,
} = workoutSlice.actions;
export default workoutSlice.reducer;
