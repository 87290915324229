import { Button, Form, Spinner } from "react-bootstrap";
import { UseAppContext } from "../../../context/appContext";
import IDay from "../../../interfaces/workouts/IDay";
import { FaCheckSquare, FaEdit, FaRegSquare } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import IWeek from "../../../interfaces/workouts/IWeek";
import {
  changeWorkoutExercice,
  editClientWorkout,
  editSubscritpionWorkout,
  setStoreCurrentWeeks,
} from "../../../store/workoutReducer";
import { toast } from "react-toastify";
import IWorkoutFull from "../../../interfaces/workouts/IWorkoutFull";
import { useState } from "react";
import IExercise from "../../../interfaces/workouts/IExercice";
import WorkoutExercise from "./WorkoutExercise";
import { MdCheck, MdClose } from "react-icons/md";
import CreateExerciseButton from "../exercises/CreateExerciseButton";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../store/actionTypes";
import { weekDone } from "../../../functions/workoutsFunctions";

interface props {
  day: IDay;
  week: number;
  weeks: Array<IWeek>;
  dayPosition: number;
}

export default function WorkoutDay({ day, week, weeks, dayPosition }: props) {
  const { translate, user, isMobile } = UseAppContext();
  const dispatch: AppDispatch = useDispatch();
  const currentWorkout = useSelector(
    (state: any) => state.workout.currentWorkout
  ) as IWorkoutFull;

  const client = currentWorkout?.client;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isProfessor = user.type === "professor";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [canEdit, setCanEdit] = useState(true);
  const [editingTitle, setEditingTitle] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(day.title || "");
  const { professor } = useParams();
  const loadingWorkouts = useSelector(
    (state: any) => state.workout.loadingWorkout
  ) as Boolean;

  function updateDay(switchDone: boolean, propDay?: IDay) {
    if (!canEdit) {
      return false;
    }

    let newWeeks = weeks.map((week) => ({
      ...week,
      days: week.days.map((day) => ({
        ...day,
        exercises: day.exercises.map((exercise) => ({ ...exercise })),
      })),
    }));

    let newWeek = { ...newWeeks[week] };
    let newDay = { ...newWeek.days[dayPosition] };

    if (propDay) {
      newDay = propDay;
    }

    if (switchDone) {
      newDay.done = !newDay.done;
    }

    newWeek.days = newWeek.days.map((day, index) =>
      index === dayPosition ? newDay : day
    );

    newWeek.done = false;

    if (newDay.done) {
      newDay.exercises = newDay.exercises.map((exercise) => ({
        ...exercise,
        done: newDay.done,
      }));

      if (!newWeek.days.some((day) => !day.done)) {
        newWeek.done = true;
      }
    }

    newWeeks[week] = newWeek;

    if (newWeeks && newWeeks.length) {
      dispatch(setStoreCurrentWeeks(newWeeks));
    }
    updateWorkoutFunction(newWeeks);
  }

  function removeExercise(position: number) {
    let newWeeks = weeks.map((week) => ({ ...week }));
    let newWeek = { ...newWeeks[week] };
    let newDay = { ...newWeek.days[dayPosition] };
    const newExercisesArray = newDay.exercises.filter(
      (_exercise, index) => index !== position
    );

    newDay.exercises = newExercisesArray;

    newWeek.days = newWeek.days.map((day, index) =>
      index === dayPosition ? newDay : day
    );

    newWeeks[week] = newWeek;
    if (newWeeks && newWeeks.length) {
      dispatch(setStoreCurrentWeeks(newWeeks));
    }

    updateWorkoutFunction(newWeeks);
  }

  function changeExercise(position: number) {
    console.error("change", position);

    const professorId = isProfessor ? user.id : professor;

    const payload = {
      exercisePosition: position,
      week: week,
      day: dayPosition,
      workoutId: currentWorkout.id,
      client: client,
      professor: professorId,
      reason: "",
    } as any;

    return dispatch(changeWorkoutExercice(payload) as any).then(() => {
      toast.success(translate("workoutUpdated"), {
        position: "top-center",
        autoClose: 1000,
        toastId: "currentUpdateWorkout",
      });
    });
  }

  function addExercise(currentExercise: IExercise) {
    if (!canEdit) {
      return false;
    }
    let newWeeks = weeks.map((week) => ({ ...week }));
    let newWeek = { ...newWeeks[week] };
    let newDay = { ...newWeek.days[dayPosition] };

    const newExercise = { ...currentExercise };
    const newExercisesArray = newDay.exercises.map((exercise) => exercise);
    newExercise.position = newExercisesArray?.length - 1;

    newExercisesArray.push(newExercise);

    newDay.exercises = newExercisesArray;

    newWeek.days = newWeek.days.map((day, index) =>
      index === dayPosition ? newDay : day
    );

    newWeeks[week] = newWeek;
    if (newWeeks && newWeeks.length) {
      dispatch(setStoreCurrentWeeks(newWeeks));
    }

    updateWorkoutFunction(newWeeks);
  }

  function updateExercise(
    exercisePosition: number,
    currentExercise: IExercise,
    switchDone: boolean
  ) {
    if (!canEdit) {
      return false;
    }

    let newWeeks = weeks.map((week) => ({ ...week }));
    let newWeek = { ...newWeeks[week] };
    let newDay = { ...newWeek.days[dayPosition] };

    const newExercise = { ...currentExercise };

    if (switchDone) {
      newExercise.done = !newExercise.done;
    }

    newDay.exercises = newDay.exercises.map((exercise, index) =>
      index === exercisePosition ? newExercise : exercise
    );

    if (!newDay.exercises.some((exercise) => !exercise.done)) {
      return updateDay(switchDone);
    }

    newWeek.days = newWeek.days.map((day, index) =>
      index === dayPosition ? newDay : day
    );

    newWeeks[week] = newWeek;
    if (newWeeks && newWeeks.length) {
      dispatch(setStoreCurrentWeeks(newWeeks));
    }

    updateWorkoutFunction(newWeeks);
  }

  function updateWorkoutFunction(newWeeks: Array<IWeek>) {
    if (!canEdit) {
      return false;
    }

    if (currentWorkout?.id && newWeeks && newWeeks.length) {
      const done = !newWeeks?.some((week) => weekDone(week) === false);

      const payload = {
        status: "",
        workoutId: currentWorkout.id,
        title: currentWorkout.title,
        weeks: (newWeeks as Array<IWeek>) || ([] as Array<IWeek>),
        done,
      } as any;

      if (!loadingWorkouts) {
        if (professor) {
          payload.professor = professor;
          return dispatch(editSubscritpionWorkout(payload) as any).then(() => {
            toast.success(translate("workoutUpdated"), {
              position: "top-center",
              autoClose: 1000,
              toastId: "currentUpdateWorkout",
            });
          });
        }

        payload.client = client;
        dispatch(editClientWorkout(payload) as any).then(() => {
          toast.success(translate("workoutUpdated"), {
            position: "top-center",
            autoClose: 1000,
            toastId: "currentUpdateWorkout",
          });
        });
      }
    }
  }

  return (
    <div className="mt-2 text-start">
      <div className="d-flex border-top p-2 pt-3">
        {editingTitle ? (
          <>
            <Form.Control
              type="text"
              name="title"
              as={isMobile ? "textarea" : undefined}
              value={currentTitle}
              onChange={(e) => {
                setCurrentTitle(e.target?.value || "");
              }}
            />
            <Button
              size="sm"
              disabled={currentTitle === day.title}
              className="ms-1"
              style={{ maxHeight: "35px" }}
              onClick={() => {
                const newDay = { ...day } as IDay;
                newDay.title = currentTitle;
                updateDay(false, newDay);
                setEditingTitle(false);
              }}
            >
              {isMobile ? <MdCheck /> : translate("edit")}
            </Button>
            <Button
              size="sm"
              className="ms-1"
              variant="outline-primary"
              style={{ maxHeight: "35px" }}
              onClick={() => {
                setEditingTitle(false);
              }}
            >
              {isMobile ? <MdClose /> : translate("cancel")}
            </Button>
          </>
        ) : (
          <>
            {isProfessor && (
              <Button
                size="sm"
                className={"d-flex " + (isMobile ? "me-0" : "me-1")}
                variant="white"
                onClick={() => {
                  setCurrentTitle(day.title || "");
                  setEditingTitle(true);
                }}
              >
                <FaEdit />
              </Button>
            )}
            <h6 className="text-start my-auto text-14">
              {day.title} {day.done && <small>| {translate("ended")}</small>}
            </h6>
            <div className="me-2 ms-auto d-flex" style={{ minHeight: "21px" }}>
              {loadingWorkouts ? (
                <Spinner size="sm" />
              ) : day.done ? (
                <FaCheckSquare
                  className="pointer my-auto"
                  size="20"
                  onClick={(e) => {
                    e.stopPropagation();
                    updateDay(true);
                  }}
                />
              ) : (
                <div
                  className="d-flex"
                  onClick={(e) => {
                    e.stopPropagation();
                    updateDay(true);
                  }}
                >
                  <span className="small me-1 my-auto">
                    {translate("endDay")}
                  </span>
                  <FaRegSquare className="pointer my-auto" size="20" />
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div>
        <CreateExerciseButton addExercice={addExercise} className="mx-auto" />
      </div>

      <div className="px-1 mt-2">
        <div>
          {day.exercises?.map((exercise, position) => (
            <WorkoutExercise
              exercise={exercise}
              exercisePosition={position || 0}
              week={week}
              weeks={weeks}
              dayDone={day.done || false}
              updateExercise={updateExercise}
              removeExercise={() => removeExercise(position)}
              changeExercise={() => changeExercise(position)}
              day={day.position}
              key={`workout-day-${position}-${exercise.exercise?.slice(0, 4)}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
